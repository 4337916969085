//2938a723-4124-4139-978d-2900237d9499
//eef3b7f0-2941-4819-bc4f-1e13fa32638b
// https://api.workrails.com/beta/catalogs/2ee3c9dc-b280-4a6e-b300-93a511fa9649
const Constants = {
  APP_BASE: process.env.REACT_APP_APP_BASE || 'https://sanity.workrails.com',
  API_BASE: process.env.REACT_APP_API_BASE || 'https://sanityapi.workrails.com/beta',
  CATALOG_ID: process.env.REACT_APP_WR_CATALOG_ID,
  COMPANY_ID: "f5033c43-0faf-4591-a53b-5fe4eb25e8fc",
  AUTH: process.env.REACT_APP_WR_ACCESS_TOKEN || 'eef3b7f0-2941-4819-bc4f-1e13fa32638b'
};
//AUTH: 'eef3b7f0-2941-4819-bc4f-1e13fa32638b', -- prod auth
//9f72d9a5-3516-4646-8800-7a55f46b2107
//88c56158-1a6d-4e90-8834-d5c22aafb3f9
export default Constants;
