import React, { Component } from "react";
import Select from 'react-select';
import { Container, Input, Button, Row, Alert } from "reactstrap";
import Product from "./Product/Product.js";
import ProductDetail from "./Product/ProductDetail.js";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _ from "lodash";
import jsPDF from "jspdf/dist/jspdf.debug.js";
import htmlDocx from "html-docx-js/dist/html-docx.js";
import juice from "juice";
import fileSaver from "file-saver";
import spacer from "../assets/spacer.png";
import ReactHtmlParser from "react-html-parser";
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { applyRate, getStatus as getRatesStatus } from '../utils/currency'
import 'flatpickr/dist/flatpickr.css';
import sowStyleTag from '../html/sow-style-tag'
import logoDataUrl from '../html/logo-data-url'
import docxFooter from '../html/docx-footer'
const dateFormats = {flatPickr: 'n/j/Y', formValue: 'M/D/YYYY'};
const jsforce = require('jsforce');
const addSubtractDate = require("add-subtract-date");

const RATE = 150
const OVERAGE_RATE = 175
const applyDiscount = (price, discount) => price - (((discount || 0) / 100) * price)
const createCurrencyFormatter = (currency) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: currency || 'USD',
  minimumFractionDigits: 2
})
const currencyFormatter = {
  format: (v, currency) => createCurrencyFormatter(currency).format(v)
}
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const euroDate = (date) => {
  return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`
}
let conn;
class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemList: [],
      isGrid: true,
      priceFilter: {
        min: 0,
        max: 0
      },
      salesforce: {
        closeDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      },
      accounts: [],
      oppError: undefined,
      isSfModalOpen: false,
      sfLoading: false,
      categorySelection: {},
      uniqueCategories: [],
      categoryParents: [],
      priceMax: 0,
      searchQuery: "",
      featuredProducts: [],
      priceUnit: "point",
      selectedProductOptions: {},
      hasSubmitted: false,
      sowBuilderProducts: [],
      sowBuilderEstPrice: 0,
      sowBuilderEstHours: 0,
      sowBuilderEstWeeks: 0,
      sowBuilderCurrentDate: new Date(),
      sowBuilderDate: '',
      sowBuilderDateOpen: true,
      sowBuilderClient: '',
      sowBuilderOppName: '',
      sowBuilderHourlyRateRegular: '150',
      sowBuilderHourlyRateOverage: '175',
      sowBuilderHoursPerMonth: {
        values: [],
        total: 0,
      },
      sowBuilderOverageHours: undefined,
      sowBuilderTerm: undefined,
      sowBuilderTerms: "",
      sowBuilderSignature: "",
      sowBuilderPreviewShowing: true,
      sowBuilderPreviewHeight: 400,
      sowBuilderDownloadDocType: "word",
      totalContractFeeScreen: "$0.00",
      generateSOW: true,
      currency: 'USD',
      discountHours: 0,
      discountOverageHours: 0,
      sowTemplateId: null
    };
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    this.sfHandleAccountChange = this.sfHandleAccountChange.bind(this);
    this.getProductOptionState = this.getProductOptionState.bind(this);
    this.clearError = this.clearError.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.setPriceFilter = this.setPriceFilter.bind(this);
    this.setGridView = this.setGridView.bind(this);
    this.setListView = this.setListView.bind(this);
    this.handleError = this.handleError.bind(this);
    this.backToPage = this.backToPage.bind(this);
    this.formatPriceFilterLabel = this.formatPriceFilterLabel.bind(this);
    this.renderProductPriceTime = this.renderProductPriceTime.bind(this);
    this.handleSowBuilderAdd = this.handleSowBuilderAdd.bind(this);
    this.isSowBuilderAdded = this.isSowBuilderAdded.bind(this);
    this.sfHandleAccountInputChange = this.sfHandleAccountInputChange.bind(this);
    this.handleSowBuilderDateOpen = this.handleSowBuilderDateOpen.bind(this);
    this.handleSowBuilderDateClose = this.handleSowBuilderDateClose.bind(this);
    this.handleSowBuilderRemove = this.handleSowBuilderRemove.bind(this);
    this.isSowBuilderTermsSignatureOption = this.isSowBuilderTermsSignatureOption.bind(this);
    this.toggleSowBuilderPreview = this.toggleSowBuilderPreview.bind(this);
    this.getSowBuilderSummary = this.getSowBuilderSummary.bind(this);
    this.setSowBuilderSummaryRef = this.setSowBuilderSummaryRef.bind(this);
    this.isSowBuilderPreviewShowing = this.isSowBuilderPreviewShowing.bind(this);
    this.setSowBuilderPreviewHeight = this.setSowBuilderPreviewHeight.bind(this);
    this.handleSowBuilderDownload = this.handleSowBuilderDownload.bind(this);
    this.isSowBuilderEmpty = this.isSowBuilderEmpty.bind(this);
    this.pageCreateOpportunity = this.pageCreateOpportunity.bind(this);
    this.buildSowBuilderDownloadVariableTagRegex = this.buildSowBuilderDownloadVariableTagRegex.bind(this);
    this.handleProductOptionSelection = this.handleProductOptionSelection.bind(this);
    this.toggleSfModal = this.toggleSfModal.bind(this);

    const defaultTemplate = _.get(this.props.templatesFetch, 'value.data[0]')
    if (defaultTemplate) {
      this.state.sowTemplateId = defaultTemplate.id
      this.state.sowBuilderTerms = defaultTemplate.content
      this.state.sowTemplateSelection = {
        label: defaultTemplate.name,
        value: defaultTemplate.id,
      }
    }
  }

  onChangeSOW = (e) => {
    const { generateSOW } = this.state

    this.setState({generateSOW: !generateSOW})
  }

  toggleSfModal(){
    if(this.state.sfLoading){
      return;
    }

    if(!this.state.hasSubmitted){
      this.setState({hasSubmitted: true});
    }

    if(!this.state.isSfModalOpen){
      this.handleSowBuilderDownload('word', undefined, true);
      this.handleSowBuilderDownload('pdf', undefined, true);
    }

    if(this.state.finalMsg){
      this.setState({finalMsg: undefined});
      this.setState({isSfModalOpen: true});
      return;
    }

    if(!this.areFieldsValid()){
      this.setState({oppError: 'Please fill out all required fields'});
      return;
    }
    this.setState({isSfModalOpen: !this.state.isSfModalOpen});
  }

  areFieldsValid(){
    const invalidRows = this.props.detailItem.rows
      .filter(row => row.options.isRequired)
      .filter(row => !this.state.selectedProductOptions[row.id])
    return (
      !invalidRows.length
      && this.state.sowBuilderTerm !== undefined
      && this.state.sowBuilderClient !== ''
      && this.state.sowBuilderOppName !== ''
      && this.state.sowBuilderDate !== ''
    )
  }

  componentDidMount() {
    if(this.props.isSalesforce){
      conn = new jsforce.Connection({ accessToken: localStorage.getItem('sf_session') });
      conn.sobject('User')
        .retrieve(localStorage.sf_userid)
        .then(user => this.setState({ sfUser: user }))
    }
    const items = this.props.data.contents.items;
    if (items) {
      //populate category arrays
      const uniqueCategories = [];
      items.forEach(item => {
        item.categories.forEach(cat => {
          const currNames = uniqueCategories.map(cat => cat.name);
          if (!currNames.includes(cat.name)) {
            uniqueCategories.push(cat);
          }
        });
      });
      this.setState({ uniqueCategories: uniqueCategories });
      const categoryParents = [];
      uniqueCategories.forEach(cat => {
        const selected = this.state.categorySelection;
        selected[cat.name] = false;
        this.setState({ selected });
        if (cat.parent_name && !categoryParents.includes(cat.parent_name)) {
          categoryParents.push(cat.parent_name);
        }
      });
      this.setState({ categoryParents: categoryParents });

      this.setState({ itemList: items });

      //calculate priors for price filter
      const maxPrice = Math.max.apply(Math, items.map(card => card.points));
      this.setState({ priceMax: maxPrice });
      this.setState({ priceFilter: { min: 0, max: maxPrice } });

      //filter out featuredProducts that wont be otherwise filterable
      this.setState({
        featuredProducts: items.filter(product => product.is_featured === true)
      });

      // if(this.props.data.terms) {
      //   this.setState({
      //     sowBuilderTerms: this.props.data.terms.setting_value,
      //   });
      // }
    }
  }

  componentDidUpdate(prevProps) {
    const { detailItem } = this.props
    if (detailItem && detailItem !== prevProps.detailItem) {
      const templateRow = detailItem.rows.find(r => r.row_type === 'component:document-template') || {}
      if (templateRow) {
        try {
          const { templateId } = JSON.parse(templateRow.control)
          const template = this.props.templatesFetch.value.data.find((v) => v.id === templateId)

          this.setState({
            sowTemplateId: templateId,
            sowTemplateSelection: {
              label: template.name,
              value: templateId,
            },
            sowBuilderTerms: template.content
          })
        } catch (ex) {
          // There's no selected template in the item
        }
      }
    }
    // Make sure that the data-table rates are used
    if (!this.areRatesUpdated && getRatesStatus() === 'loaded') {
      this.areRatesUpdated = true
      this.setState({
        sowBuilderHourlyRateRegular: this.getRate(false).toString(),
        sowBuilderHourlyRateOverage: this.getRate(true).toString()
      })
    }
  }

  handleSearchQueryChange(event) {
    const val = event.target.value.toLowerCase();
    this.setState({ searchQuery: val });
  }
  handleSearchClear() {
    this.setState({ searchQuery: "" });
  }

  setPriceFilter(value) {
    value.min = Math.max(value.min, 0);
    value.max = Math.min(value.max, this.state.priceMax);
    this.setState({ priceFilter: value });
  }
  isPriceFiltered() {
    return (
      this.state.priceFilter.min > 0 ||
      this.state.priceFilter.max < this.state.priceMax
    );
  }
  formatPriceFilterLabel(value) {
    const normalizedValue = value >= 1000 ? value / 1000 + "k" : value;
    return `${normalizedValue} ${this.state.priceUnit}${
      value !== 1 ? "s" : ""
    }`;
  }


  setGridView() {
    this.setState({ isGrid: true });
  }

  setListView() {
    this.setState({ isGrid: false });
  }

  renderProductPriceTime(item, opts) {
    opts = opts || {};
    return null;
    /*const points = `${item.points.toLocaleString()} ${this.state.priceUnit}${
      item.points !== 1 ? "s" : ""
    }`;
    const duration = item.duration
      ? `${item.duration.toLocaleString()} hour${
          item.duration !== 1 ? "s" : ""
        }`
      : null;
    if (points || duration) {
      return (
        <div className="priceTime">
          {[
            points ? <span key={`p${item.id}`}>{points}</span> : null,
            opts.download ? <span> | </span> : null,
            duration ? <span key={`d${item.id}`}>{duration}</span> : null
          ]}
        </div>
      );
    }*/
  }

  backToPage() {
    this.props.setPageDetail();
  }

  getItems() {
    let itemList = this.props.data.contents.items;

    if (!itemList) {
      return null;
    }

    //ptc specific point handling based on rows
    itemList.forEach(card => {
      card.points = 0;
      card.duration = 0;
      if (card.rows) {
        const points = card.rows.find(
          row =>
            row.label.trim() === "Success Points"
        );
        if (points && points.control && !isNaN(points.control)) {
          card.points = parseInt(points.control);
        }
        const duration = card.rows.find(
          row =>
            row.label.trim() === "Duration"
        );
        if (duration && duration && !isNaN(duration.control)) {
          card.duration = parseInt(duration.control);
        }
      }
    });

    //handle category filtering
    if (Object.values(this.state.categorySelection).includes(true)) {
      itemList = itemList.filter(card =>
        card.categories
          .map(cat => cat.name)
          .some(cat => this.state.categorySelection[cat])
      );
      if (itemList.length === 0) {
        return (
          <h3 className="error">
            Your selected categories have no associated items.
          </h3>
        );
      }
    }

    itemList = itemList.filter(
      card =>
        (card.name.toLowerCase().indexOf(this.state.searchQuery) !== -1 ||
          card.description.toLowerCase().indexOf(this.state.searchQuery) !==
            -1) &&
        (card.points >= this.state.priceFilter.min &&
          card.points <= this.state.priceFilter.max)
    );

    if (itemList.length === 0) {
      if (this.state.searchQuery !== "") {
        return (
          <h3 className="error">
            Your search query did not produce any results. Please try again.
          </h3>
        );
      } else {
        return (
          <h3 className="error">
            Your price filter did not produce any results. Please try again.
          </h3>
        );
      }
    }
    const display = this.state.isGrid;
    return (
      <Row className="items">
        {itemList.map((item, index) => {
          return (
            <Product
              log_transaction={this.props.log_transaction}
              data={item}
              index={index}
              key={index}
              isGrid={display}
              renderProductPriceTime={this.renderProductPriceTime}
              setPageDetail={this.props.setPageDetail}
              isSowBuilderAdded={this.isSowBuilderAdded}
            />
          );
        })}
      </Row>
    );
  }

  handleCategorySelect(event) {
    if (!event.target.name) {
      return;
    }
    const selected = this.state.categorySelection;
    selected[event.target.name] = !selected[event.target.name];
    this.setState({ selected });
  }


  handleProductOptionSelection(row, _value, option) {
    const { selectedProductOptions } = this.state;
    selectedProductOptions[row.id] = _value;
    this.setState({ selectedProductOptions });

    //setting vars for displayed totals here
    var screenTerm = !isNaN(this.state.sowBuilderTerm) ? this.state.sowBuilderTerm : 0;
    let screenHPM = this.state.sowBuilderHoursPerMonth
    var screenOH = !isNaN(this.state.sowBuilderOverageHours) ? this.state.sowBuilderOverageHours : 0;
    let currency = this.state.currency
    let sowBuilderHourlyRateRegular = this.state.sowBuilderHourlyRateRegular
    let sowBuilderHourlyRateOverage = this.state.sowBuilderHourlyRateOverage
    sowBuilderHourlyRateRegular = this.getRate(false, { currency }).toString()
    sowBuilderHourlyRateOverage = this.getRate(true, { currency }).toString()

    let value = !isNaN(_value) && _value.length !== 0 ? parseInt(_value) : 0;
    if (row.label.toLowerCase() === "term of contract") {
      this.setState({
        sowBuilderTerm: value
      });
      //set the screen value
      screenTerm = value;
    } else if (/hours per month/i.test(row.label)) {
      const hpmValues = this.props.detailItem.rows
        .filter(r => /hours per month/i.test(r.label))
        .map(r => selectedProductOptions[r.id])
      screenHPM = {
        values: hpmValues,
        total: hpmValues.reduce((sum, val) => sum + Number(val), 0) || 0,
      }

      this.setState({
        sowBuilderHoursPerMonth: screenHPM
      });
    } else if (row.label.toLowerCase() === "overage hours") {
      this.setState({
        sowBuilderOverageHours: value
      });
      //set the screen value
      screenOH = value;

    } else if (row.label.toLowerCase() === 'currency') {
      currency = _value
      sowBuilderHourlyRateRegular = this.getRate(false, { currency }).toString()
      sowBuilderHourlyRateOverage = this.getRate(true, { currency }).toString()
      this.setState({
        currency,
        sowBuilderHourlyRateRegular,
        sowBuilderHourlyRateOverage
      })
    } else if (row.label.toLowerCase().includes('discount')) {
      const key = row.options.tag === 'discountHours' ? 'discountHours' : 'discountOverageHours'
      sowBuilderHourlyRateRegular = this.getRate(false, { [key]: value }).toString()
      sowBuilderHourlyRateOverage = this.getRate(true, { [key]: value }).toString()
      this.setState({
        [key]: value,
        sowBuilderHourlyRateRegular,
        sowBuilderHourlyRateOverage
      })
    } else if (_.get(row.options, 'tag', '').toLowerCase() === 'newexhibit') {
      this.setState({
        sowBuilderNewExhibit: option.description
      })
    }

    //set values for Total on Screen
    var monthlyFixedFee = screenHPM.total * sowBuilderHourlyRateRegular;
    var totalFixedFee = monthlyFixedFee * screenTerm;
    var travelExpenses = screenOH * sowBuilderHourlyRateOverage;
    var totalContractFee = totalFixedFee + travelExpenses;

    this.setState({
      totalContractFeeScreen: currencyFormatter.format(totalContractFee, currency),
    });
  }

  handleSowBuilderAdd(product) {
    const { sowBuilderProducts } = this.state;
    sowBuilderProducts.push(product);

    this.setState({ sowBuilderProducts: sowBuilderProducts }, () => {
      this.calcSowBuilderSummary();
    });

    if (!this.isSowBuilderPreviewShowing()) {
      this.toggleSowBuilderPreview();
    }
  }

  handleSowBuilderRemove(product, e) {
    if (e) e.preventDefault();

    const { sowBuilderProducts } = this.state;
    sowBuilderProducts.splice(sowBuilderProducts.indexOf(product), 1);
    this.setState({ sowBuilderProducts: sowBuilderProducts }, () => {
      this.calcSowBuilderSummary();
    });

    if (
      !this.state.sowBuilderProducts.length &&
      this.isSowBuilderPreviewShowing()
    ) {
      this.toggleSowBuilderPreview();
    }
  }

  isSowBuilderAdded(product) {
    const { sowBuilderProducts } = this.state;
    return sowBuilderProducts.indexOf(product) !== -1;
  }

  isSowBuilderPreviewShowing() {
    return this.state.sowBuilderPreviewShowing;
  }

  isSowBuilderEmpty() {
    return !this.state.sowBuilderProducts.length;
  }

  toggleSowBuilderPreview() {
    let { sowBuilderPreviewShowing } = this.state;
    sowBuilderPreviewShowing = !sowBuilderPreviewShowing;
    this.setState({ sowBuilderPreviewShowing: sowBuilderPreviewShowing });

    if (!this.props.detailItem) this.props.isDimmed(sowBuilderPreviewShowing);
  }

  setSowBuilderPreviewHeight() {
    const sowBuilderSummaryElem = document.getElementsByClassName(
      "sowBuilderSummary"
    )[0];
    if (sowBuilderSummaryElem) {
      const sowBuilderSummaryRect = sowBuilderSummaryElem.getBoundingClientRect();
      this.setState({
        sowBuilderPreviewHeight:
          window.innerHeight - sowBuilderSummaryRect.top - 150
      });
    }
  }

  buildSowBuilderDownloadVariable(name, opts) {
    return `&lt;${opts && opts.closingTag ? "/" : ""}${name}&gt;`;
  }

  buildSowBuilderDownloadVariableTagRegex(name) {
    return new RegExp(
      this.buildSowBuilderDownloadVariable(name) +
        "(.*?)" +
        this.buildSowBuilderDownloadVariable(name, { closingTag: true }),
      "g"
    );
  }

  handleSowBuilderDownloadSigField(field) {
    const fieldLeft =
      field === "Customer" ? this.state.sowBuilderClient : field;
    return (
      "<pre>" +
      fieldLeft +
      "&#9;".repeat(5 - Math.floor(Math.max(0, fieldLeft.length - 2) / 10)) +
      (field === "Customer" ? "erwin, Inc." : field) +
      '</pre><br><br><pre style="font-family:Arial !important;">_________________________________&#9;_________________________________</pre><br>'
    );
  }

  handleSowBuilderDownloadPageBreak(html) {
    return html.replace(
      new RegExp(this.buildSowBuilderDownloadVariable("Page Break"), "g"),
      '<br class="pagebreak" clear="all" style="page-break-before:always" />'
    );
  }

  cleanSowBuilderDownload(docType, params = {}) {
    var cleanElem = document.createElement("div");
    if(!this.props.isSalesforce){
      var stylesheet = "";
      for (var i in document.styleSheets) {
        if (
          document.styleSheets[i].href &&
          document.styleSheets[i].href.indexOf("SowBuilderDownload") !== -1
        ) {
          stylesheet = document.styleSheets[i];
        }
      }
      var styles = "";
      for (var s = 0; s < stylesheet.cssRules.length; ++s) {
        styles += stylesheet.cssRules[s].cssText;
      }
      cleanElem.innerHTML =
        "<style>" +
        styles +
        "</style>" +
        document.getElementById(" ").outerHTML;
    }else{
      cleanElem.innerHTML = sowStyleTag + document.getElementById("sowBuilderDownload").outerHTML;
    }

    const currency = this.state.currency

    var startDateArray = this.state.sowBuilderDate.split('/');
    var startDateYear = startDateArray.pop();
    startDateArray.unshift(startDateYear);

    var dStartDate = new Date(startDateArray);
    var dCloseDate = new Date(this.state.sowBuilderDate);
    dCloseDate.setDate(0);

    var dExperationDate = addSubtractDate.add(dStartDate, this.state.sowBuilderTerm, "months");
    dExperationDate.setMonth(dExperationDate.getMonth());
    dExperationDate.setDate(0);

    const hpm = this.state.sowBuilderHoursPerMonth
    const hourlyRate = this.state.sowBuilderHourlyRateRegular
    
    const fixedFee = {}
    ;([fixedFee.total, ...fixedFee.values] = [hpm.total, ...hpm.values].map(hpmValue => {
      const monthly = hpmValue * hourlyRate
      const total = monthly * this.state.sowBuilderTerm
      return { monthly, total }
    }))

    var travelExpenses = (this.state.sowBuilderOverageHours || 0)*this.state.sowBuilderHourlyRateOverage;
    var totalContractFee = fixedFee.total.total + travelExpenses;

    var termsElem = cleanElem.querySelector(".terms .content");
    if (termsElem) {
      termsElem.innerHTML = termsElem.innerHTML
        .replace(
          this.buildSowBuilderDownloadVariable("Pricing"),
          currencyFormatter.format(this.state.sowBuilderEstPrice, currency)
        )
        .replace(
          this.buildSowBuilderDownloadVariable("Estimated Weeks"),
          '<span class="estweeks">' +
            this.state.sowBuilderProducts.length * 4 +
            " weeks</span>"
        )
        .replace(
          this.buildSowBuilderDownloadVariable("Total Hours"),
          this.state.sowBuilderEstHours
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Date"), "g"),
          this.state.sowBuilderDate
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("dateEur"), "g"),
          euroDate(moment(this.state.sowBuilderDate, 'MM/DD/YYYY').toDate())
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("CloseDate"), "g"),
          (dCloseDate.getMonth()+1) + "/" + dCloseDate.getDate()  +  "/" + dCloseDate.getFullYear()
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("closeDateEur"), "g"),
          euroDate(dCloseDate)
        )
        .replace(
          this.buildSowBuilderDownloadVariableTagRegex("Sig Field"),
          (match, field) => this.handleSowBuilderDownloadSigField(field)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Customer"), "g"),
          this.state.sowBuilderClient
        )
        .replace(
            new RegExp(this.buildSowBuilderDownloadVariable("Opportunity Name"), "g"),
            this.state.sowBuilderOppName
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Hours Per Month"), "g"),
          this.state.sowBuilderHoursPerMonth.total
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Term"), "g"),
          this.state.sowBuilderTerm
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Monthly Fixed Fee(CRM|Vault)?"), "g"),
          (m, p1) => currencyFormatter.format(fixedFee.values[p1 === 'Vault' ? 1 : 0].monthly, currency)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Total Fixed Fee(CRM|Vault)?"), "g"),
          (m, p1) => currencyFormatter.format(fixedFee.values[p1 === 'Vault' ? 1 : 0].total, currency)
        )
        .replace(
          this.buildSowBuilderDownloadVariable("Exp Date"),
          (dExperationDate.getMonth()+1) + "/" + dExperationDate.getDate()  +  "/" + dExperationDate.getFullYear()
        )
        .replace(
          this.buildSowBuilderDownloadVariable("expDateEu"),
          euroDate(dExperationDate)
        )
        .replace(
          this.buildSowBuilderDownloadVariable("Current Date"),
          (this.state.sowBuilderCurrentDate.getMonth()+1) + "/" + this.state.sowBuilderCurrentDate.getDate()  +  "/" + this.state.sowBuilderCurrentDate.getFullYear()
        )
        .replace(
          this.buildSowBuilderDownloadVariable("CreateDate"),
          (this.state.sowBuilderCurrentDate.getMonth()+1) + "/" + this.state.sowBuilderCurrentDate.getDate()  +  "/" + this.state.sowBuilderCurrentDate.getFullYear()
        )
        .replace(
          this.buildSowBuilderDownloadVariable("createDateEur"),
          euroDate(this.state.sowBuilderCurrentDate)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Overage Hours"), "g"),
          (this.state.sowBuilderOverageHours || 0)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Overage Fixed Fee"), "g"),
          currencyFormatter.format(this.state.sowBuilderHourlyRateOverage, currency)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Travel Expenses"), "g"),
          currencyFormatter.format(travelExpenses, currency)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Total Contract Fee"), "g"),
          currencyFormatter.format(totalContractFee, currency)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("opportunityOwner"), "g"),
          (this.state.sfUser || {}).Name || ''
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("opportunityCurrency"), "g"),
          currency
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("editions"), "g"),
          (_.get(params, 'opportunity.Editions__c') || '').replace(/;/g, ', ')
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("orderNumber"), "g"),
          _.get(params, 'order.Unique_ID__c') || ''
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("orderType"), "g"),
          _.get(params, 'order.Service_Type__c') || ''
        )

        ;

      if (docType !== "word") {
        termsElem.innerHTML = this.handleSowBuilderDownloadPageBreak(
          termsElem.innerHTML
        );
      }

      var contentElem = cleanElem.querySelector("#sowBuilderDownload");
      var exhibitVariableMatch = this.buildSowBuilderDownloadVariableTagRegex("Exhibit").exec(contentElem.innerHTML);
      if(exhibitVariableMatch) {
        contentElem.innerHTML = contentElem.innerHTML.substring(0, exhibitVariableMatch.index) + contentElem.innerHTML.substring(exhibitVariableMatch.index + exhibitVariableMatch[0].length, contentElem.innerHTML.length);
        contentElem.innerHTML = contentElem.innerHTML + exhibitVariableMatch[1];
      }
      const newExhibitRX = new RegExp(this.buildSowBuilderDownloadVariable("newExhibit"), "g")
      if (newExhibitRX.exec(contentElem.innerHTML)) {
        const sowBuilderNewExhibit = this.state.sowBuilderNewExhibit || ''
        contentElem.innerHTML = contentElem.innerHTML
          .replace(newExhibitRX, sowBuilderNewExhibit.replace(/\$/g, '$$$$'))
      }
    }

    var signatureElem = cleanElem.querySelector(".signature .content");
    if (signatureElem) {
      signatureElem.innerHTML = signatureElem.innerHTML
        .replace(
          this.buildSowBuilderDownloadVariableTagRegex("Sig Field"),
          (match, field) => this.handleSowBuilderDownloadSigField(field)
        )
        .replace(
          new RegExp(this.buildSowBuilderDownloadVariable("Customer"), "g"),
          this.state.sowBuilderClient
        );
    }

    [].forEach.call(cleanElem.querySelectorAll("ol"), function(n) {
      if (
        n.firstChild.tagName === "LI" &&
        n.firstChild.getAttribute("data-list") === "bullet"
      ) {
        n.outerHTML = "<ul>" + n.innerHTML + "</ul>";
      }
    });

    if (docType === "word") {
      [].forEach.call(cleanElem.querySelectorAll(".ql-align-center"), function(
        n
      ) {
        n.innerHTML = "<center>" + n.innerHTML + "</center>";
      });
      [].forEach.call(cleanElem.querySelectorAll("hr"), function(n) {
        n.parentNode.removeChild(n);
      });
      [].forEach.call(cleanElem.querySelectorAll("p, h2"), function(n) {
        if (n.innerHTML === "<br>") {
          n.innerHTML = "-";
          n.className = "emptyRow";
        }
      });
    } else {
      [].forEach.call(cleanElem.querySelectorAll("p, li"), function(n) {
        if (n.innerHTML === "&nbsp;" && n.tagName.toLowerCase() !== 'p') {
          n.parentNode.removeChild(n);
        }

        if (
          n.classList.contains("ql-indent-1") ||
          n.classList.contains("ql-indent-2")
        ) {
          if (n.innerHTML === "<br>") {
            n.innerHTML = "";
          } else {
            var indent = document.createElement("div");
            indent.classList.add("indent");

            if (n.tagName === "P" && n.firstChild.nodeType === Node.TEXT_NODE) {
              n.innerHTML = "<span>" + n.innerHTML + "</span>";
            }
            if (n.classList.contains("ql-indent-1")) {
              indent.innerHTML = "<ul><li>" + n.innerHTML + "</li></ul>";
            } else if (n.classList.contains("ql-indent-2")) {
              indent.innerHTML =
                "<ul><li><ul><li>" + n.innerHTML + "</li></ul></li></ul>";
            }
            n.innerHTML = indent.outerHTML;
          }
        } else {
          if (n.innerHTML === "<br>") {
            n.innerHTML = "";
            var indentSpacer = document.createElement("img");
            indentSpacer.setAttribute("src", spacer);
            n.appendChild(indentSpacer);
            n.className = "emptyRow";
          }
        }
      });
    }

    return cleanElem;
  }
  sfHandleAccountChange(selection){
    this.setState({sfAccountText: undefined});
    this.setState({sowBuilderClient: selection.label});
    this.setState({sfAccount:selection});
  }

  receiveRecords(err,records){
    this.setState({sfAccountLoading: true});
    if (err) { return console.error(err); }
    this.setState({accounts: records});
  }
  sfHandleAccountInputChange(e, opts){
    if(opts.action === 'input-change'){
      this.setState({sfAccountLoading: true});
      this.setState({sfAccountText: e});
      if(e.length >= 3){
        conn.sobject("Account")
          .find(
            { Name : { $like : '%' + e + '%' } },
            { Id: 1,
              Name: 1,
              }
          )
          .execute(this.receiveRecords.bind(this));
      }
    }
  }

  generateDoc = (params) => {
    var downloadElem = document.getElementById("sowBuilderDownload");
    downloadElem.classList.add("active");

    downloadElem.classList.add("doc");

    var cleanElem = this.cleanSowBuilderDownload("word", params);

    [].forEach.call(cleanElem.querySelectorAll("li"), function(li) {
      // Remove margins that came in via pasting from MS Word or similar
      // The goal is to prevent breakage of bullet indentation
      li.style.margin = null
    });
    // [].forEach.call(cleanElem.querySelectorAll("table"), function(table) {
    //   if (table.cellPadding) {
    //     const padding = Number(table.cellPadding);
    //     table.cellPadding = "";
    //     [].forEach.call(table.querySelectorAll("td"), function (td) {
    //       td.style.padding = `${3 * padding}px ${2 * padding}px ${padding}px`
    //     })
    //   }
    // });

    const customImg = cleanElem.querySelector('.terms .content p:first-child img')
    if (customImg) {
      // Remove the default header image if the user provided their own
      cleanElem.querySelector('img.logo').remove()
    }

    var images = cleanElem.querySelectorAll("img");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    [].forEach.call(images, function(imgElement) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;
      ctx.drawImage(imgElement, 0, 0);
      var dataURL = canvas.toDataURL();
      imgElement.setAttribute("src", dataURL);
    });
    canvas.remove();

    var docHtml =
      "<!DOCTYPE html>" +
      "<html>" +
      "<head><meta charset=\"utf-8\" /></head>" +
      `<body style="margin: 0; padding: 0;">` +
      docxFooter() +
      this.handleSowBuilderDownloadPageBreak(juice(cleanElem.outerHTML)) +
      "</body>" +
      "</html>";

    docHtml = docHtml.replace(/“/g, '"').replace(/”/g, '"')
    downloadElem.classList.remove("active");
    downloadElem.classList.remove("doc");

    var converted = htmlDocx.asBlob(docHtml, {
      orientation: 'portrait',
      margins: { top: 300, left: 720, right: 720, bottom: 0, footer: 0 }
    });
    return converted;
  }

  handleSowBuilderDownload(docType, e, forOpp) {
    if(!forOpp){
      this.setState({hasSubmitted: true});
      if(!this.areFieldsValid()){
        return;
      }else if(this.state.hasSubmitted){
        this.setState({hasSubmitted: false});
      }
    }
    if (docType && e) {
      e.stopPropagation();
    }
    docType = docType || this.state.sowBuilderDownloadDocType;
    this.setState({ sowBuilderDownloadDocType: docType }, function() {
      if (docType === "word") {
        if (!forOpp) {
          var converted = this.generateDoc()
          fileSaver.saveAs(converted, "SOW.docx");
        }
      } else {
        var downloadElem = document.getElementById("sowBuilderDownload");
        downloadElem.classList.add("active");
        downloadElem.classList.add("pdf");

        var pdf = new jsPDF("p", "pt", "letter");
        /*pdf.setLineWidth(1); pdf.setDrawColor(225, 225, 225); pdf.rect(10, 83, 200, 55);*/
        var margins = { top: 20, bottom: 20, left: 20, right: 20 };
        pdf.fromHTML(
          this.cleanSowBuilderDownload(docType),
          margins.left,
          margins.top,
          {
            width: 550
          },
          function(dispose) {
            if(!forOpp){
              pdf.save("SOW.pdf");
              downloadElem.classList.remove("active");
              downloadElem.classList.remove("pdf");
            }
          },
          margins
        );
        if(forOpp){
          this.setState({sfPdf: pdf});
          return;
        }
    }
    });
  }


  toAscii(str) {
    return str
    // Remove sanitization as it's blocking intentional use of some unicode chars
    // str = str.replace(/&(l|g)t;/g, '{$1t}');
    // var tmp = document.createElement('textarea');
    // tmp.innerHTML = str;
    // str = tmp.value.replace(/[\u0100-\uffff]/g, ch => {
    //   switch (ch) {
    //     case "“":
    //     case "”":
    //     case "”�":
    //       return '"';
    //     case "’":
    //     case "‘":
    //       return "'";
    //     default:
    //       return "";
    //   }
    // });
    // str = str.replace(/{(l|g)t}/g, '&$1t;');
    // return str;
  }

  isSowBuilderTermsSignatureOption(option) {
    return option.terms || option.signature;
  }

  calcSowBuilderSummary() {
    let estPrice = 0,
      estHours = 0,
      estWeeks = 0;
    const { sowBuilderProducts } = this.state;
    sowBuilderProducts
      .concat(
        [].concat.apply(
          [],
          sowBuilderProducts.map(function(p) {
            return p.options || [];
          })
        )
      )
      .forEach(p => {
        if (this.isSowBuilderTermsSignatureOption(p)) {
          return;
        }
        let duration = p.duration || 0;
        if (p.points) {
          estPrice += p.points;
        } else if (p.serviceRateLabel && p.serviceRateLabel !== "") {
          const m = p.serviceRateLabel.match(/[\d,]+/);
          if (m.length) {
            const n = parseInt(m[0].replace(",", ""), 10);
            if (!isNaN(n)) {
              if (p.serviceRateLabel.toLowerCase().indexOf("day") !== -1) {
                duration = n * 8;
              } else if (
                p.serviceRateLabel.toLowerCase().indexOf("week") !== -1
              ) {
                duration = n * 40;
              } else {
                estPrice += n;
              }
            }
          }
        }
        estHours += duration;
      });
    estWeeks = Math.round((estHours / 40) * 2) / 2;

    this.setState({
      sowBuilderEstPrice: estPrice,
      sowBuilderEstHours: estHours,
      sowBuilderEstWeeks: estWeeks
    });
  }

  pageCreateOpportunity(opp, docName, overageHours, staffings, orders, staffingTwo, orderTwo, itemId, accountName){
    this.setState({isSfModalOpen: false});
    this.setState({sfLoading: true});
    const currency = this.state.currency
    opp.CurrencyIsoCode = currency
    staffings.forEach(staffing => { staffing.CurrencyIsoCode = currency })
    orders.forEach(order => { order.CurrencyIsoCode = currency })
    if (staffingTwo) staffingTwo.CurrencyIsoCode = currency
    if (orderTwo) orderTwo.CurrencyIsoCode = currency

    this.props.sfCreateOpportunity(opp, this.generateDoc, this.state.sfPdf, docName, overageHours, staffings, orders, this.handleError, staffingTwo, orderTwo, this.state.generateSOW, itemId, accountName);
  }

  handleError(msg, oppId){
    this.setState({sfLoading: false});
    this.toggleSfModal();
    this.setState({finalMsg: msg});
    this.setState({oppId: oppId});
  }

  handleSowBuilderDateOpen(dateObj, dateStr, instance) {
    this.setState({sowBuilderDateOpen: true}, () => {
      if(!this.state.sowBuilderDate) {
        instance.jumpToDate(moment().add(30, 'days').format(dateFormats.formValue));
      }
    });
  }

  clearError(){
    this.setState({oppError: null});
  }

  handleSowBuilderDateClose() {
    this.setState({sowBuilderDateOpen: false});
  }

  handleSowBuilderClientChange(event) {
    this.setState({
      sowBuilderClient: event.target.value
    });
  }
  handleSowBuilderDateChange(value) {
    const date = new Date(value[0]);
    date.setDate(1);
    this.setState({
      sowBuilderDate: moment(date).format(dateFormats.formValue),
      sowRawStartDate: date,
    });
  }
  handleSowBuilderOppNameChange(event) {
    this.setState({
      sowBuilderOppName: event.target.value
    });
  }

  handleSowTemplateChange = (selection) => {
    const { templatesFetch } = this.props
    const templates = _.get(templatesFetch, 'value.data')
    const template = templates.find((v) => v.id === selection.value)

    this.setState({
      sowTemplateId: selection.value,
      sowTemplateSelection: selection,
      sowBuilderTerms: template.content
    })
  }


  getSowBuilderSummary(product) {
    const { templatesFetch } = this.props
    const templates = templatesFetch.value.data
    /*const {
      sowBuilderEstPrice,
      sowBuilderEstHours,
      sowBuilderEstWeeks
    } = this.state;*/
    return (
      <div
        ref={this.setSowBuilderSummaryRef}
        className={`sowBuilderSummary ${
          this.isSowBuilderPreviewShowing() ? "preview-showing" : ""
        } ${this.state.sowBuilderProducts.length ? "has-products" : ""}`}
      >
        <div className="cta">
          {!this.props.isSalesforce &&
            <button
              className="download"
              onClick={(e) => this.handleSowBuilderDownload("word", e)}
            >
              <div className="label">SOW</div>
            </button>
          }
          <button
            className="preview"
            onClick={() => this.toggleSowBuilderPreview()}
          >
            <div className="label">Summary</div>
          </button>
        </div>
        <div className="summary">
          {this.state.sowBuilderProducts.length ?
              <div className={`variables ${this.props.isSalesforce ? 'variables-salesforce' : ''}`}>
                {this.props.isSalesforce ?
                    <div>
                      <Select clearable={true} placeholder="Account Name" noOptionsMessage={() => this.state.sfAccountText && this.state.sfAccountText.length >3 ? this.state.sfAccountLoading ? 'Loading...' : 'No accounts match your query.' : 'Enter 3 or more characters.'} style={{width: '150px'}} value={this.state.sfAccount} onInputChange={this.sfHandleAccountInputChange} inputValue={this.state.sfAccountText} onChange={this.sfHandleAccountChange}
                              className={this.state.sowBuilderClient.trim() === '' && this.state.hasSubmitted ? "invalid" : ""}
                              options= {this.state.accounts.map(function(account) {
                                return {label:account.Name, value:account.Id}
                              })} />
                    </div>
                    :
                <input
                    type="text"
                    className={this.state.sowBuilderClient.trim()  === '' && this.state.hasSubmitted ? "invalid" : ""}
                    value={this.state.sowBuilderClient}
                    placeholder="Client Name"
                    onChange={event => { this.handleSowBuilderClientChange(event) }} />}

                <div style={{ margin: '1rem 0' }}>
                  <Select
                    placeholder="Choose SOW Template"
                    noOptionsMessage={() => "No templates found"}
                    onChange={this.handleSowTemplateChange}
                    value={this.state.sowTemplateSelection}
                    styles={{
                      indicatorSeparator: () => null
                    }}
                    options={templates.map((template) => ({
                      label: template.name,
                      value: template.id,
                    }))}
                  />
                </div>

                <input
                    type="text"
                    className={this.state.sowBuilderOppName.trim()  === '' && this.state.hasSubmitted ? "invalid" : ""}
                    value={this.state.sowBuildereppName}
                    placeholder="Opp Name / SOW Num"
                    onChange={event => { this.handleSowBuilderOppNameChange(event) }} />
                <Flatpickr
                    className={this.state.sowBuilderDate.trim() === '' && this.state.hasSubmitted ? "invalid" : ""}
                    value={this.state.sowBuilderDate}
                    placeholder="Start Date"
                    options={{dateFormat: dateFormats.flatPickr, minDate: moment().format(dateFormats.formValue)}}
                    onOpen={this.handleSowBuilderDateOpen}
                    onClose={this.handleSowBuilderDateClose}
                    onChange={value => { this.handleSowBuilderDateChange(value) }} />
              </div>
              : null}
              <div className={`total ${this.props.isSalesforce ? 'total-salesforce' : ''}`}>Total: <span>{this.state.totalContractFeeScreen}</span></div>

              {this.props.isSalesforce &&
                <div className='cta cta-create-opportunity'>
                  <div className='generate-sow'>
                    <input id="generate-sow-checkbox" type="checkbox" checked={this.state.generateSOW} onChange={this.onChangeSOW} />
                    <label htmlFor='generate-sow-checkbox'>Generate SOW</label>
                  </div>
                  <button className="opportunity-button" onClick={this.toggleSfModal}>Create Opportunity</button>
                </div>
              }

        </div>
        {(!this.areFieldsValid() && this.state.hasSubmitted) && <Alert color="danger">Please fill out all required fields.</Alert>}
        <div className="download ql-snow" id="sowBuilderDownload">
          <div className="ql-editor ql-container">
            <center><img src={logoDataUrl} crossOrigin="Anonymous" className="logo" align="center" alt="" /></center>
            <div className="summary">
              <div className="sep">-</div>
            </div>
            {_.map(this.state.sowBuilderProducts, (product, p) => {
              return (
                <div className="item" key={p}>
                  {p > 0 && (
                    <div>
                      <hr />
                      <div className="sep">
                        _____________________________________________________________________________________________________________
                      </div>
                    </div>
                  )}
                  {product.rows &&
                    _.map(product.rows, (row, r) => {
                      let value = row.control;
                      if (row.control && row.control[0] === '"') {
                        value = value.substring(1, row.control.length - 1).replace(/\\n/g, '');
                      }

                      if(['editor','text'].includes(row.row_type) && value) {
                        return (
                            <div className="productRow" key={`${p}_${r}`}>
                              {row.label !== "Exhibit 1" &&
                              <em>
                                <h2>
                                  {row.label}
                                </h2>
                              </em>}

                              <div>
                                {ReactHtmlParser(this.toAscii(value.replace(/\\"/g, '"')))}
                              </div>
                              <div>
                                <hr />
                                <div className="sep">
                                  _____________________________________________________________________________________________________________
                                </div>
                              </div>
                              {_.map(
                                  _.filter(row.options.items, { isChecked: true }),
                                  (option, o) => {
                                    return (
                                        <div className="option" key={`${p}_${r}_${o}`}>
                                          <div
                                              className={`sep ${o === 0 ? "first" : ""}`}
                                          >
                                            -
                                          </div>
                                          <h5>{option.label}</h5>
                                          {this.renderProductPriceTime(option, {
                                            download: true
                                          })}
                                          <p
                                              dangerouslySetInnerHTML={{
                                                __html: this.toAscii(option.description)
                                              }}
                                          />
                                        </div>
                                    );
                                  }
                              )}
                            </div>
                        );
                      }
                      else {
                          return null;
                      }
                    })}
                </div>
              );
            })}

            {this.state.sowBuilderTerms && (
              <div className="terms">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: this.toAscii(this.state.sowBuilderTerms) }}
                />
                <hr />
                <div className="sep">
                  _____________________________________________________________________________________________________________
                </div>
              </div>
            )}

            {this.state.sowBuilderSignature && (
              <div className="signature">
                <hr />
                <div className="sep">
                  _____________________________________________________________________________________________________________
                </div>
                <div className="content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.toAscii(this.state.sowBuilderSignature)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  setSowBuilderSummaryRef(node) {
    this.sowBuilderSummaryRef = node;
  }

  getProductOptionState(row){
    if (row.label.toLowerCase() === "term of contract") {
        return this.state.sowBuilderTerm;
    } else if (row.label.toLowerCase() === "hours per month") {
        return this.state.sowBuilderHoursPerMonth.total;
    } else if (row.label.toLowerCase() === "overage hours") {
        return this.state.sowBuilderOverageHours;
    }
  }

  getRate = (overage = false, modifiers = {}) => {
    const currency = modifiers.currency || this.state.currency
    const discountHours = !modifiers.hasOwnProperty('discountHours') ? this.state.discountHours : modifiers.discountHours
    const discountOverageHours = !modifiers.hasOwnProperty('discountOverageHours') ? this.state.discountOverageHours : modifiers.discountOverageHours

    return overage
      ? parseFloat(applyDiscount(applyRate(OVERAGE_RATE, currency), discountOverageHours).toFixed(2))
      : parseFloat(applyDiscount(applyRate(RATE, currency), discountHours).toFixed(2))
  }

  render() {
    if (this.props.detailItem) {
    const data = this.props.getSfData();
    const lightdata = {};
    Object.assign(lightdata,data);
    delete lightdata.accounts;
      return (
        <ProductDetail
          getRate={this.getRate}
          generateSOW={this.state.generateSOW}
          sfLoading={this.state.sfLoading}
          oppId = {this.state.oppId}
          finalMsg={this.state.finalMsg}
          totalCost ={this.state.totalContractFeeScreen}
          sfCreateOpportunity={this.pageCreateOpportunity}
          requestedOpenOpp={this.state.hasSubmitted}
          sowBuilderDate = {this.state.sowBuilderDate}
          sowBuilderTerm = {this.state.sowBuilderTerm}
          getProductOptionState = {this.getProductOptionState}
          sowBuilderHoursPerMonth = {this.state.sowBuilderHoursPerMonth}
          sowBuilderOverageHours = {this.state.sowBuilderOverageHours}
          sowBuilderOppName = {this.state.sowBuilderOppName}
          sowRawStartDate = {this.state.sowRawStartDate}
          getSfData = {() => {
            return lightdata;
          }}
          clearError = {this.clearError}
          sfAccount = {this.state.sfAccount}
          isSfModalOpen ={this.state.isSfModalOpen}
          toggleSfModal = {this.toggleSfModal}
          isSalesforce={this.props.isSalesforce}
          isLoggedIn={this.props.isLoggedIn}
          productRowsFetch={this.props.productRowsFetch}
          productRowsLazyFetch={this.props.productRowsLazyFetch}
          log_transaction={this.props.log_transaction}
          data={this.props.detailItem}
          backToPage={this.backToPage}
          renderProductPriceTime={this.renderProductPriceTime}
          getSowBuilderSummary={this.getSowBuilderSummary}
          setSowBuilderPreviewHeight={this.setSowBuilderPreviewHeight}
          isSowBuilderPreviewShowing={this.isSowBuilderPreviewShowing}
          isSowBuilderEmpty={this.isSowBuilderEmpty}
          toggleSowBuilderPreview={this.toggleSowBuilderPreview}
          isSowBuilderAdded={this.isSowBuilderAdded}
          handleSowBuilderAdd={this.handleSowBuilderAdd}
          handleSowBuilderRemove={this.handleSowBuilderRemove}
          buildSowBuilderDownloadVariableTagRegex={this.buildSowBuilderDownloadVariableTagRegex}
          handleProductOptionSelection={this.handleProductOptionSelection}
          selectedProductOptions={this.state.selectedProductOptions}
          templatesFetch={this.props.templatesFetch}
        />
      );
    }
    const data = this.props.data;
    return (
      <div className="page">
        <div className="hero">
          <div>
            <div className="titles">
              <Container>
                <Row>
                  <h1 className="title">{data.name}</h1>
                  <h2 className="subtitle">{data.description}</h2>
                </Row>
              </Container>
            </div>
            {this.state.featuredProducts.length > 0 && (
              <Container>
                <Row className="featured justify-content-center">
                  {this.state.featuredProducts.map((item, index) => {
                    return (
                      <Product
                        className="product-featured"
                        data={item}
                        index={index}
                        key={index}
                        log_transaction={this.props.log_transaction}
                        isGrid={true}
                        setPageDetail={this.props.setPageDetail}
                        renderProductPriceTime={this.renderProductPriceTime}
                        isSowBuilderAdded={this.isSowBuilderAdded}
                      />
                    );
                  })}
                </Row>
              </Container>
            )}

            <div className="categories">
              <Container>
                <Row>
                  {this.state.categoryParents.map(parentName => {
                    return (
                      <div
                        key={parentName}
                        className={
                          "col col-lg-" +
                          Math.floor(12 / this.state.categoryParents.length)
                        }
                      >
                        <div>
                          <div className="parent">{parentName}</div>
                          <div className="list">
                            <ul>
                              {this.state.uniqueCategories
                                .filter(cat => cat.parent_name === parentName)
                                .map(cat => {
                                  return (
                                    <li key={cat.name}>
                                      <Button
                                        outline
                                        onClick={this.handleCategorySelect}
                                        name={cat.name}
                                        color="secondary"
                                        className={classNames({
                                          active: this.state.categorySelection[
                                            cat.name
                                          ]
                                        })}
                                        size="sm"
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            this.state.categorySelection[
                                              cat.name
                                            ]
                                              ? "check-square"
                                              : "square"
                                          }
                                        />
                                        {cat.name}
                                      </Button>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Container>
          {this.props.data.contents.items.length > 5 && (
            <Row
              className={`options ${
                this.isSowBuilderEmpty() ? "sowBuilderEmpty" : ""
              }`}
            >
              {this.props.data.contents.items && (
                <div className="search">
                  <Input
                    placeholder="Search"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchQueryChange}
                  />
                  {this.state.searchQuery && (
                    <FontAwesomeIcon
                      icon="times"
                      onClick={this.handleSearchClear}
                    />
                  )}
                </div>
              )}
              <div className="listGridToggle">
                <Button
                  className={classNames({ active: this.state.isGrid })}
                  onClick={this.setGridView}
                >
                  <FontAwesomeIcon icon="th" />
                </Button>
                <Button
                  className={classNames({ active: !this.state.isGrid })}
                  onClick={this.setListView}
                >
                  <FontAwesomeIcon icon="list-ul" />
                </Button>
              </div>
              {this.state.priceMax > 0 && (
                <div
                  className={
                    "price" + (this.isPriceFiltered() ? " filtered" : "")
                  }
                >
                  <InputRange
                    maxValue={this.state.priceMax}
                    minValue={0}
                    step={1}
                    formatLabel={this.formatPriceFilterLabel}
                    value={this.state.priceFilter}
                    onChange={this.setPriceFilter}
                  />
                </div>
              )}
              {this.getSowBuilderSummary()}
            </Row>
          )}
          {this.getItems()}
        </Container>
      </div>
    );
  }
}

export default Page;
