import React, { Component } from "react";
import Flatpickr from 'react-flatpickr';
import ReactHtmlParser from "react-html-parser";
import {Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import sfLogo from '../../assets/Salesforce-Logo.png';
import _ from "lodash";
import Select from 'react-select';
import {
  Container,
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//import ReactMarkdown from 'react-markdown/with-html';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
// eslint-disable-next-line
String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
const dateFormats = {flatPickr: 'n/j/Y', formValue: 'M/D/YYYY'};

const BUSINESS_LINE_REVENUE_DEPT_MAP = {
  Commercial: ['Asia Commercial Services', 'Business Consulting Svcs', 'EU Commercial Svcs', 'KOL Svcs', 'LA Commercial Svcs', 'Medrix Svcs', 'NA Commercial Svcs', 'Nitro Svcs', 'OpenData Svcs', 'Training Services'],
  'CP&C': ['EU CP&C Services', 'NA CP&C Services'],
  Medtech: ['Medtech Svcs', 'Business Consulting Svcs'],
  'R&D': ['Asia R&D Services', 'Business Consulting Svcs', 'CDMS Svcs', 'EU R&D Svcs', 'LA R&D Svcs', 'NA R&D Svcs'],
}

const BUSINESS_LINE_OPTS = Object.keys(BUSINESS_LINE_REVENUE_DEPT_MAP)
  .map((v) => ({ label: v, value: v }))

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "main",
      tabs: [],
      salesforce: {
        revenuedept: [],
        edition: {},
        resourcename: {},
      },
      hasSubmitted: false,
      missingFields: [],
      loading: false,
      productRows: "",

      options: [],
      dropdownOpen: {},
    };
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleCheckOption = this.handleCheckOption.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.sfHandleChange = this.sfHandleChange.bind(this);
    this.onSfSubmit = this.onSfSubmit.bind(this);
  }

  onSfSubmit(hpmRows){
    const { getRate } = this.props
    const endDate = new Date(this.props.sowBuilderDate);
    endDate.setMonth(endDate.getMonth() + this.props.sowBuilderTerm);
    endDate.setDate(0);
    const endDateString = (moment(endDate).format('YYYY-MM-DD'));
    let nextFirstMonday = undefined;
    const iterDate = new Date(this.props.sowBuilderDate);
    let firstMonday = new Date(iterDate.getFullYear(), iterDate.getMonth(), 1 +
       (1 - new Date(iterDate.getFullYear(), iterDate.getMonth(), 1).getDay() + 7) % 7);
    nextFirstMonday = firstMonday;
    this.setState({hasSubmitted: true});
    if(!this.areFieldsValid()){
      return;
    }else{
      this.setState({hasSubmitted: false});
    }
    const closeDate = new Date(this.props.sowRawStartDate.getFullYear(), this.props.sowRawStartDate.getMonth(),0);
    const editions = this.state.salesforce.edition[0].map(edition => edition.label)
    const screenHpm = this.props.sowBuilderHoursPerMonth
    const ovh = this.props.sowBuilderOverageHours
    const term = this.props.sowBuilderTerm

    const businessLine = this.state.salesforce.businessline.value
    this.props.sfCreateOpportunity(
      // opp
      {
        Name: this.props.sowBuilderOppName,
        WorkRails__c: true,
        Status__c: 'Forecast',
        RecordTypeId: localStorage.getItem("sf_opp_record_type"),
        StageName: this.state.salesforce.stage.value,
        closeDate: moment(closeDate).format('YYYY-MM-DD'),
        AccountId: this.props.sfAccount.value,
        Region__c: this.state.salesforce.region.label,
        Business_Line__c: businessLine,
        Order_Start_Date__c: moment(this.props.sowBuilderDate).format('YYYY-MM-DD'),
        Revenue_Department__c: this.state.salesforce.revenuedept.map((revenuedept) => { return revenuedept.label}).join(';'),
        Editions__c: editions.join(';'),
        Managed_Services_Renewal__c: true},
      // docName
      this.state.salesforce.docname ? this.state.salesforce.docname :  this.props.sowBuilderOppName + '-' + moment().format(dateFormats.formValue),
      // overageHours
      ovh,
      // staffing
      hpmRows.map((_, i) => ({
        Services_Role__c: this.props.getSfData().services_roles.find((role) => role.Name === 'Managed Services').Id,
	      Week_Starting__c:moment(nextFirstMonday).format('YYYY-MM-DD'),
        Week_Ending__c: endDateString,
        Duration__c: term,
        Hours__C: screenHpm.values[i] * term,
        Work_Order_Hours__c: screenHpm.values[i] * term,
        Work_Order_Rate__c: getRate(),
        Rate__c: getRate(),
        Services_Resource__c: this.state.salesforce.resourcename[i].value,
        Requested_Resource_Location__c: this.state.salesforce.reqresource.label,
        RecordTypeId: localStorage.getItem("sf_staffing_record_type"),
        ...((editions.length === 1)
            ? { Product__c: editions[0] }
            : {}),
      })),
      // order
      hpmRows.map((_, i) => ({
        SOW_Total__c: screenHpm.values[i] * term * getRate(),
        Start_Date__c: moment(this.props.sowBuilderDate).format('YYYY-MM-DD'),
        End_Date__c: moment(endDate).format('YYYY-MM-DD'),
        Order_Term__c: term,
        Service_Type__c: "Managed Services",
        WorkRails__c: true,
        MS_Contact__c: this.state.salesforce.resourcename[i].value,
        Managed_Services_Fixed_Monthly_Fee__c: screenHpm.values[i] * getRate(),
        Managed_Services_Monthly_Budget__c: screenHpm.values[i],
        Managed_Services_Total__c: screenHpm.values[i] * term,
        Overage_Rate__c: 0,
        GEO__c: this.state.salesforce.region.label,
        Revenue_Department__c: this.state.salesforce.revenuedept.map((revenuedept) => { return revenuedept.label}).join(';'),
        Editions__c: editions[i],
        RecordTypeId: localStorage.getItem("sf_order_record_type"),
        Services_Business_Line__c: businessLine,
      })),
      // staffingTwo
      {
        Services_Role__c: this.props.getSfData().services_roles.find((role) => role.Name === 'Managed Services Overage').Id,
	      Week_Starting__c:moment(nextFirstMonday).format('YYYY-MM-DD'),
        Week_Ending__c: endDateString,
        Duration__c:term,
        Hours__C: ovh,
        Rate__c: getRate(true),
        Work_Order_Hours__c: ovh,
        Work_Order_Rate__c: getRate(true),
        Requested_Resource_Location__c: this.state.salesforce.reqresource.label,
        RecordTypeId: localStorage.getItem("sf_staffing_record_type"),
        ...((editions.length === 1)
            ? { Product__c: editions[0] }
            : {}),
      },
      // order two
      {
        SOW_Total__c: ovh * getRate(true),
        Start_Date__c: moment(this.props.sowBuilderDate).format('YYYY-MM-DD'),
        End_Date__c: moment(endDate).format('YYYY-MM-DD'),
        Order_Term__c: term,
        Service_Type__c: "Managed Services",
        WorkRails__c: true,
        MS_Contact__c: this.state.salesforce.resourcename.value,
        Managed_Services_Fixed_Monthly_Fee__c: null,
        Managed_Services_Monthly_Budget__c: null,
        Managed_Services_Total__c: ovh,
        Overage_Rate__c: getRate(true),
        GEO__c: this.state.salesforce.region.label,
        Revenue_Department__c: this.state.salesforce.revenuedept.map((revenuedept) => { return revenuedept.label}).join(';'),
        Editions__c: editions.join(';'),
        RecordTypeId: localStorage.getItem("sf_order_record_type"),
        Services_Business_Line__c: businessLine,
      }, this.props.data.id, this.props.sfAccount.label);
  }

  areFieldsValid(){
    const { salesforce } = this.state;
    const hpmRows = this.props.data.rows.filter(r => /hours per month/i.test(r.label))

    return (
      salesforce.revenuedept.length > 0
      && salesforce.region !== undefined
      && (!salesforce.docname || salesforce.docname.length > 0)
      && salesforce.reqresource !== undefined
      && hpmRows.every((_, i) => (salesforce.edition[i] || []).length > 0)
    )
  }


  componentDidMount() {
    if(this.props.isSalesforce){
      const { salesforce } = this.state;
      const defaultStageName=this.props.getSfData().stages.find((row) => row.ApiName=== "Proposal/Price Quote").ApiName;
      salesforce.stage = {label:defaultStageName, value:defaultStageName};
      salesforce.region = {label: this.props.getSfData().regions[0], value: this.props.getSfData().regions[0]};
      salesforce.reqresource = {label: this.props.getSfData().regions[0], value: this.props.getSfData().regions[0]};
      salesforce.revenuedept = salesforce.revenuedept.filter(dept => dept.value !== 'Vault Comm Svcs' && dept.value !== 'Vault R&D Svcs')
      this.setState({salesforce});
    }
    const item = this.props.data;
    const tabs = [
      {
        title:
          item.main_tab_label && item.main_tab_label.trim().length > 0
            ? item.main_tab_label
            : "Main",
        content: []
      }
    ];
    this.setState({ activeTab: tabs[0].title });
    let currentTab = 0;
    item.rows.forEach(row => {
      if (row.options.addToCatalogTab === true) {
        tabs.push({
          title: row.label,
          content: [row]
        });
        currentTab = tabs.length - 1;
      } else if (
        row.row_type === "style:header" ||
        row.options.hasOwnHeader === true
      ) {
        currentTab = 0;
        tabs[0].content.push(row);
      } else {
        tabs[currentTab].content.push(row);
      }
    });
    this.setState({ tabs });
    window.scrollTo(0, 0);

    this.props.setSowBuilderPreviewHeight();
    window.addEventListener("resize", this.props.setSowBuilderPreviewHeight);
    if (!this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "productRowsFetch.pending") &&
      !_.get(this.props, "productRowsFetch.pending")
    ) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.props.setSowBuilderPreviewHeight);

    if (this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  handleTabClick(e) {
    this.setState({ activeTab: e.target.value });
  }

  getTabs() {
    const tabs = this.state.tabs;
    return (
      <div className="productDetailTabDisplay">
        <div className="tabList">
          {tabs.map(t => {
            const activeClass =
              t.title === this.state.activeTab ? "active" : "";
            return (
              <button
                onClick={this.handleTabClick}
                value={t.title}
                className={activeClass}
                key={t.title}
              >
                {t.title}
              </button>
            );
          })}
        </div>
        <div className="tabContent">
          {tabs
            .find(tab => tab.title === this.state.activeTab)
            .content.map((item, index) => {
              return this.renderRow(item);
            })}
        </div>
      </div>
    );
  }

  handleCheckOption(value) {
    const { options } = this.state;
    if (options.includes(value)) {
      options.splice(options.indexOf(value));
    } else {
      options.push(value);
    }
    this.setState({ options });
  }

  sfHandleChange(e){
    const salesforce = this.state.salesforce;
    salesforce[e.target.name] = e.target.value;
    this.setState({salesforce});
  }

  sfHandleSelectChange(e, key){
    const salesforce = this.state.salesforce;
    if(key === "region"){
      salesforce.reqresource = e;
    }
    salesforce[key] = e;
    this.setState({salesforce});
  }

  toggleDropdown(e) {
    const { name } = e.target;
    if (!name) {
      this.setState({
        dropdownOpen: {}
      });
    } else {
      this.setState({
        dropdownOpen: {
          [name]: !this.state.dropdownOpen[name]
        }
      });
    }
  }


  renderRow(row) {
    if (
      this.state.tabs.length > 0 &&
      this.state.activeTab === this.state.tabs[0].title &&
      !row.is_public
    ) {
      return null;
    }
    if (row.description === "Regional Pricing") {
      if (
        row.control &&
        row.control.trim() !== "" &&
        row.control.trim() !== '""'
      ) {
        return (
          <div key={row.id} className="section">
            <h2>
              {row.label}:{row.control}
            </h2>
          </div>
        );
      } else {
        return null;
      }
    }
    if(row.row_type === "text"){
      if(row.options.hasQuestionnaire === true){
        const value = this.props.selectedProductOptions[row.id] || ''
        return (
          <div key={row.id} className="section">
            {row.label}:
            <Input
              type="number"
              min="0"
              invalid={!value && row.options.isRequired && this.props.requestedOpenOpp}
              placeholder="Enter Value"
              size="sm"
              value={value}
              onChange={(e) => this.props.handleProductOptionSelection(row, e.target.value)}
            />
          </div>
        );
      } else {
        const val = row.control ? row.control.substring(1,row.control.length-1) : '';
        return <div key={row.id} className="section">{row.label.replace("[CUSTOMER FACING]", "")}:  {val}</div>
      }
    }
    if (row.row_type === "dropdown") {
      const dropdownOptions = _.map(row.options.items, i => {
        return JSON.parse(i.value)
      });
      const dropdownSelection = dropdownOptions.find(i => i.value === this.props.selectedProductOptions[row.id]);
      return (
        <div key={row.id} className="section">
          <label htmlFor={`row-select-${row.id}`}>{row.label}:</label>

          <Dropdown name={row.id} className={this.props.getProductOptionState(row) === undefined && this.props.requestedOpenOpp ? "invalid" : ""} isOpen={this.state.dropdownOpen[row.id]} toggle={this.toggleDropdown} size="sm">
            <DropdownToggle name={row.id} caret outline className={dropdownSelection ? 'selected' : ''}>{dropdownSelection ? dropdownSelection.label : 'Choose Option'}</DropdownToggle>
            <DropdownMenu>
              {_.map(dropdownOptions, (o, i) => {
                return (
                  <DropdownItem key={`row-select-${row.id}-${i}`} onClick={() => this.props.handleProductOptionSelection(row, o.value, o)}>{o.label}</DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    }
    if (row.row_type === "checkbox") {
      if (row.options.items.map(item => item.isChecked).includes(true)) {
        return (
          <div key={row.id} className="section">
            <h2>{row.label}</h2>
            {row.options.items.map(check => {
              if (check.isChecked) {
                return (
                  <div key={check.value} className="checkoption">
                    {check.label}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      }
      return (
        <div key={row.id} className="section">
          <h2>{row.label}</h2>
          {row.options.items.map(check => {
            return (
              <div key={check.value} className="checkoption">
                <input
                  type="checkbox"
                  onChange={this.handleCheckOption.bind(this, check.label)}
                  checked={this.state.options.includes(check.label)}
                />
                {check.label}
              </div>
            );
          })}
        </div>
      );
    } else if (row.row_type === "component:faqs") {
      return (
        <ul className="faqs">
          {row.control.map(f => {
            return (
              <li key={f.id} className="faq">
                <div className="question">{f.question}</div>
                <div className="answer">{f.answer}</div>
              </li>
            );
          })}
        </ul>
      );
    }
    if (row.row_type === "component:document-template") {
      return null
    }

    let value = row.control;
    if (row.control && row.control[0] === '"') {
      value = value.substring(1, row.control.length - 1);
    }

    return value ? (
      <div key={row.id} className="section">
        {row.label !== "Exhibit 1" && <h2>{row.label}</h2>}
        <div>{ReactHtmlParser(value.replace(/\\"/g, '"').replace(/\\n/g, '').replace(this.props.buildSowBuilderDownloadVariableTagRegex("Exhibit"), '$1'))}</div>
      </div>
    ) : null;
  }

  handleDateChange(date){
    const salesforce = this.state.salesforce;
    salesforce.closeDate = date;
    this.setState({ salesforce })
  }

  getModal(){
    if(this.props.finalMsg === true){
      return <Alert color="success">Opportunity Successfully Created! <a target="_blank" rel="noopener noreferrer" href={'https://' + window.location.hostname + '/' + this.props.oppId}>View In Salesforce</a></Alert>
    }else if(this.props.finalMsg){
      return <Alert color="danger">{this.props.finalMsg}</Alert>
    }else if(this.props.sfLoading){
      return <Alert color="primary">Creating Opportunity...</Alert>
    }
    /*
                        <div className={this.state.salesforce.edition.length === 0 && this.state.hasSubmitted ? 'input invalid': 'input'} >
                          <label>Service Resource Name:</label>
                          <Select value={this.state.salesforce.resourcename} onChange={(e) => this.sfHandleSelectChange(e,'resourcename')}
                            options= {this.props.getSfData().services_resources.map(function(dept) {
                              return {label:dept.Name, value:dept.Id}
                            })} />
                        </div>
                        */

    const hpmRows = this.props.data.rows
      .filter(r => /hours per month/i.test(r.label))
      .map(row => ({ row, prefix: (row.label.match(/(CRM|Vault) /i) || [''])[0] }))

    return (
        <Modal className="opportunity" isOpen={this.props.isSfModalOpen} toggle={this.props.toggleSfModal}>
          <ModalHeader toggle={this.props.toggleSfModal}>
            <img src={'https://veeva.workrails.com' + sfLogo} alt='logo_not_found' />
            Creating Opportunity <span style={{color:"blue"}}>{this.props.sowBuilderOppName}</span> for Account: <span style={{color:"purple"}}>{this.props.sfAccount && this.props.sfAccount.label}</span>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="parent">
                <div className="navOpp">
                  <div className="sfIcon" />
                </div>
                <div className="mainOpp">
                  <div className="inputOpp">
                    <div className="left">
                      <div className={this.state.salesforce.revenuedept.length === 0 && this.state.hasSubmitted ? 'input invalid': 'input'} >
                        <label>Business Line:</label>
                        <Select value={this.state.salesforce.businessline} onChange={(e) => {
                          this.sfHandleSelectChange(e,'businessline')
                          this.sfHandleSelectChange([],'revenuedept')
                        }}
                                options={BUSINESS_LINE_OPTS} />
                      </div>
                      <div className={this.state.salesforce.revenuedept.length === 0 && this.state.hasSubmitted ? 'input invalid': 'input'} >
                        <label>Revenue Department:</label>
                        <Select isMulti={true} value={this.state.salesforce.revenuedept} onChange={(e) => this.sfHandleSelectChange(e,'revenuedept')}
                                options= {this.props.getSfData().revenuedepts.filter((v) => {
                                  const allowedValues = BUSINESS_LINE_REVENUE_DEPT_MAP[this.state.salesforce.businessline && this.state.salesforce.businessline.value]
                                  if (!allowedValues) {
                                    return false
                                  } else {
                                    return allowedValues.includes(v.replace('Services : ', ''))
                                  }
                                }).map(function(dept) {
                                  return {label:dept, value:dept}
                                })} />
                      </div>
                      <div className="input">
                        <label>Week Starting:</label>
                        <Flatpickr
                          id='startDate'
                          value={this.props.sowBuilderDate}
                          options={{dateFormat: dateFormats.flatPickr, minDate: moment().format(dateFormats.formValue)}}
                          onChange={(date) => this.handleDateChange(date)}
                          disabled />
                      </div>
                      <div className="input">
                        <label>Close Date:</label>
                        <Flatpickr
                          id='closeDate'
                          value={this.props.sowRawStartDate && new Date(this.props.sowRawStartDate.getFullYear(), this.props.sowRawStartDate.getMonth(),0)}
                          options={{dateFormat: dateFormats.flatPickr, minDate: moment().format(dateFormats.formValue)}}
                          onChange={(date) => this.handleDateChange(date)}
                          disabled
                        />
                      </div>
                      <div className="input">
                        <label>Stage Name:</label>
                        <Select value={this.state.salesforce.stage} onChange={(e) => this.sfHandleSelectChange(e,'stage')}
                                options= {this.props.getSfData().stages.map(function(dept) {
                                  return {label:dept.ApiName, value:dept.ApiName}
                                })} />
                      </div>
                      <div className={this.state.salesforce.region === undefined && this.state.hasSubmitted ? 'input invalid': 'input'} >
                        <label>Region:</label>
                        <Select value={this.state.salesforce.region} onChange={(e) => this.sfHandleSelectChange(e,'region')}
                                options= {this.props.getSfData().regions.map(function(dept) {
                                  return {label:dept, value:dept}
                                })} />
                      </div>
                      {hpmRows.map(({ prefix }, i) => {
                        const edt = this.state.salesforce.edition[i] || []
                        const otherEdt = this.state.salesforce.edition[1 - i] || []
                        return (
                          <div
                            key={i}
                            className={!edt.length && this.state.hasSubmitted ? 'input invalid': 'input'}
                          >
                            <label>{prefix}Edition:</label>
                            <Select
                              isMulti={true}
                              value={edt}
                              onChange={(e) => {
                                this.setState(s => ({
                                  salesforce: {
                                    ...s.salesforce,
                                    edition: {
                                      ...s.salesforce.edition,
                                      [i]: e,
                                    }
                                  }
                                }));
                              }}
                              options={this.props.getSfData().editions
                                  .filter(sfEd => !otherEdt.find(ed => sfEd === ed.value))
                                  .map((dept) => ({ label: dept, value: dept }))
                              }
                            />
                          </div>
                        )
                      })}
                      <div className="input">
                        <label>Document Name</label>
                        <Input className="input" placeholder="" name="docname" value={this.state.salesforce.docname ? this.state.salesforce.docname : this.props.sowBuilderOppName + '-' + moment().format(dateFormats.formValue)} onChange={this.sfHandleChange} required/>
                      </div>
                      {hpmRows.map(({ prefix }, i) => {
                        const resName = this.state.salesforce.resourcename[i]
                        return (
                          <div
                            key={i}
                            className={!resName && this.state.hasSubmitted ? 'input invalid': 'input'}
                          >
                            <label>{prefix}Service Resource Name:</label>
                            <Select
                              value={resName}
                              onChange={(e) => {
                                this.setState(s => ({
                                  salesforce: {
                                    ...s.salesforce,
                                    resourcename: {
                                      ...s.salesforce.resourcename,
                                      [i]: e,
                                    }
                                  }
                                }));
                              }}
                              options={this.props.getSfData().services_resources.map(function(dept) {
                                return {label:dept.Name, value:dept.Id}
                              })} />
                          </div>
                        )
                      })}
                    </div>
                    <div className="right">
                      <div className="input">
                        <label>Contract Service Budget:</label>
                        <Input placeholder="" name="name" value={this.props.totalCost} disabled/>
                      </div>
                      <div className="input">
                        <label>Services Role:</label>
                        <Input placeholder="" name="name" value={this.props.sowBuilderOverageHours > 0 ? 'MS and MS Overage' : 'MS'} disabled/>
                      </div>
                      <div className="input">
                        <label>Duration:</label>
                        <Input placeholder="" name="name" value={this.props.sowBuilderTerm + ' Months'} disabled/>
                      </div>
                      <div className="input">
                        <label>Total Contract Hours:</label>
                        <Input placeholder="" name="name" value={this.props.sowBuilderHoursPerMonth.total * this.props.sowBuilderTerm} disabled/>
                      </div>
                      <div className='input' >
                        <label>Requested Resource Location:</label>
                        <Select value={this.state.salesforce.reqresource} onChange={(e) => this.sfHandleSelectChange(e,'reqresource')}
                                options= {this.props.getSfData().regions.map(function(dept) {
                                  return {label:dept, value:dept}
                                })} />
                      </div>
                      <div className="input">
                        <label>Product:</label>
                        <Input placeholder="" name="name" value={this.props.data.name.includes('Vault') ? 'Vault' : 'CRM'} disabled/>
                      </div>
                    </div>
                  </div>
                  <Button className="submit" onClick={() => this.onSfSubmit(hpmRows)}>Submit</Button>
                  {this.state.hasSubmitted && !this.areFieldsValid() && <Alert color="danger">Please fill in all required fields.</Alert>}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="poweredBy">Powered by <a href="https://www.workrails.com" target="_blank" rel="noopener noreferrer">WorkRails</a></div>
          </ModalFooter>
        </Modal>
    );
  }

  render() {
    const subtitle = this.props.renderProductPriceTime(this.props.data);
    const renderRow = this.renderRow;
    if(this.props.isSalesforce && !this.props.getSfData().stages){
      this.props.backToPage();
    }
    return (
      <Container className="detail ql-snow">
        {this.props.isSalesforce && this.getModal()}
        <Button
          onClick={this.props.backToPage}
          color="link"
         $ className="backButton"
        >
          <FontAwesomeIcon icon="long-arrow-alt-left" />
          Back
        </Button>
        {this.props.data && this.props.data.description ? (
          <Card onClick={this.handleSelect} className={`${this.props.isSowBuilderPreviewShowing() ? "preview-showing" : ""} ${!this.props.isSowBuilderEmpty() ? "has-products" : ""}`}>
            <CardHeader>
              <CardTitle>{this.props.data.name}</CardTitle>
              {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
              <div className="cta">
                {this.props.isSowBuilderAdded(this.props.data) ? (
                  <button
                    className="added"
                    onClick={() =>
                      this.props.handleSowBuilderRemove(this.props.data)
                    }
                  >
                    Added to SOW
                  </button>
                ) : (
                  <button
                    className="add"
                    onClick={() =>
                      this.props.handleSowBuilderAdd(this.props.data)
                    }
                  >
                    Add to SOW
                  </button>
                )}
              </div>
            </CardHeader>
            <CardBody className="ql-editor ql-container" style={{ overflow: "auto" }}>
              <div className="description">{this.props.data.description}</div>
              {this.state.tabs.length > 1 ? (
                this.getTabs()
              ) : (
                <div className="rows">
                  {this.props.data.rows.map(function(item, index) {
                    return renderRow(item);
                  })}
                </div>
              )}
            </CardBody>
          </Card>
        ) : (
          <h1>Something went wrong.</h1>
        )}
        {this.props.getSowBuilderSummary(this.props.data)}
      </Container>
    );
  }
}

/*
                  <div className="mainOpp">
                    <div className="inputOpp">
                      <label>Opportunity Name</label>
                      <input type="text" name="name" value={this.state.name} onChange={this.handleChange} required/>
                      <label>Account Name</label>
                      <input type="text" name="account" value={this.props.sfAccountName} disabled/>
                      <label>Close Date</label>
                      <Flatpickr
                        id='closeDate'
                        value={this.state.closeDate}
                        options={{dateFormat: dateFormats.flatPickr, minDate: moment().format(dateFormats.formValue)}}
                        onChange={(date) => this.handleDateChange(date)}
                      />
                      <label>Stage Name</label>
                      {this.props.stageList ?
                      <select  name="stage" value={this.state.stage} onChange={this.handleChange} required>
                        {this.props.stageList.map(function(stage) {
                          return <option key={stage} value={stage}>{stage}</option>
                        })}
                      </select> : <input type="text" name="stage" value={this.state.stage} onChange={this.handleChange} required/> }
                      <label>Document Name</label>
                      <input type="text" name="docName" value={this.state.docName} onChange={this.handleChange} required/>
                      <label>Amount</label>
                    <input type="number" step="1000" name="amount" value={this.state.amount} onChange={this.handleChange} required/>
                    </div>
                  </div>
                  <div className="poweredBy">Powered by <a href="https://www.workrails.com" target="_blank" rel="noopener noreferrer">WorkRails</a></div>
                </div>
*/
export default ProductDetail;
