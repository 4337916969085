import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import { TrackJS } from 'trackjs';

if (process.env.NODE_ENV !== 'development') {
  TrackJS.install({
    token: 'e7442525f69e441eb1a8441efdce14fd',
    application: 'veeva'
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
