import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";
import classNames from "classnames";
const colors = [
  "blue",
  "orange",
  "green",
  "green",
  "green",
  "orange",
  "green",
  "orange",
  "blue"
];

class Product extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  setProductContainerDimensions() {
    setTimeout(() => {
      if (this.productContainer) {
        this.productContainer.style.minWidth = `${
          this.productContainer.offsetWidth
        }px`;
        this.productContainer.style.minHeight = `${
          this.props.className === "product-featured"
            ? this.productContainer.offsetHeight
            : this.props.isGrid
            ? 210
            : 150
        }px`;
      }
    }, 0);
  }

  resize = () => this.clearProductContainerDimensions();
  clearProductContainerDimensions() {
    this.productContainer.style.minWidth = "";
    //this.productContainer.style.minHeight = '';
    setTimeout(() => {
      this.setProductContainerDimensions();
    }, 0);
  }

  componentDidMount() {
    this.setProductContainerDimensions();
    window.addEventListener("resize", this.resize);
  }
  componentDidUpdate(prevProps) {
    if (_.get(prevProps, "isGrid") !== _.get(this.props, "isGrid")) {
      this.clearProductContainerDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  handleSelect() {
    this.props.log_transaction("viewed", "product", this.props.data.id, this.props.data.name);
    this.props.setPageDetail(this.props.data);
  }
  render() {
    const item = this.props.data;

    const colorClassName = classNames(colors[this.props.index % colors.length]);
    return (
      <div
        ref={node => (this.productContainer = node)}
        className={classNames(
          "product-container",
          this.props.className,
          this.props.isGrid ? "col-lg-4" : "col-lg-12"
        )}
      >
        <div>
          <Card onClick={this.handleSelect} className={colorClassName}>
            <CardHeader className={colorClassName}>
              <CardTitle>{item.name}</CardTitle>
            </CardHeader>
            <CardBody style={{ overflow: "auto" }}>
              <CardText>{item.description}</CardText>
              <Button className={colorClassName}>Get Started</Button>
            </CardBody>
          </Card>
          <div
            className={`sowBuilder ${
              this.props.isSowBuilderAdded(item) ? "active" : ""
            }`}
          >
            <button className="added">Added to SOW</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
