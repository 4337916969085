const currencyRates = {
  hourlyRate: 0,
  overageHourlyRate: 0,
  eurHourlyRate: 0,
  eurOverageHourlyRate: 0,
}

/** @type {'loading' | 'loaded'} */
let status = 'loading'

export const setCurrencyRates = (rates) => {
  Object.entries(rates).forEach(([k, v]) => {
    currencyRates[k] = v
  })
  status = 'loaded'
}

export const getStatus = () => {
  return status
}

export const applyRate = (price, rate = 'USD') => {
  if (!rate || rate === 'USD') {
    return price === 150
      ? currencyRates.hourlyRate
      : currencyRates.overageHourlyRate
  } else if (rate === 'EUR') {
    let ret = price === 150
      ? currencyRates.eurHourlyRate
      : currencyRates.eurOverageHourlyRate
    return ret
  }
}
