import React, { Component } from "react";
import { connect } from "react-refetch";
import Constants from "./Constants.js";
import _ from "lodash";
import Site from "./components/Site.js";
//import ldata from './catalog-prototype.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { setCurrencyRates } from './utils/currency'
import moment from 'moment';
const jsforce = require('jsforce');
library.add(fas);
var toBuffer = require('blob-to-buffer')

const getUser = function() {
  return isSalesforce() ? { id: localStorage.getItem('sf_userid'), email: localStorage.getItem('sf_email') } : {};
};

const sfData = {};
// remove this
window.wrTriggerDeploy = {}

const isSalesforce = function(){
  return window.location.href.includes('force') && window.location.href.indexOf('.com') > window.location.href.indexOf('force');
};

/*const getUserAuth = function(user) {
  if(!user && !getUser()){
    return {'Content-type':'application/json', 'Authorization':Constants.AUTH};
  }

  return {
    'Content-type': 'application/json',
    'Authorization': (user||getUser()).authorization
  };
};*/

let conn = new jsforce.Connection({ accessToken: localStorage.getItem('sf_session') });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_public: true,
      loading: true,
      dimmed: false,
      data: {},
      geo: {}
    };
    this.log_transaction = this.log_transaction.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.isDimmed = this.isDimmed.bind(this);
    this.sfCreateOpportunity = this.sfCreateOpportunity.bind(this);
  }

  sfFetchAllRecords = (query, dataKey) => {
    const records = []
    conn.query(query)
      .on("record", function (record) {
        records.push(record);
      })
      .on("end", function () {
        sfData[dataKey] = records;
      })
      .on("error", function (err) {
        console.error(err);
      })
      .run({ autoFetch : true, maxFetch : 10000 });
  }

  async sfCreateOpportunity (opp, docGen, pdf, docName, overageHours, staffings, orders, callback, staffingTwo, orderTwo, generateSOW, itemId, accountName) {
    const log_transaction = this.log_transaction;
    const lazyCreateProposal = this.props.lazyCreateProposal;
    const companyId= this.props.catalogFetch.value.data.company_id;
    const parentId= this.props.catalogFetch.value.data.id;
    try {
      const ret = await conn.sobject("Opportunity").create(opp)
      const oppId = ret.id;
      log_transaction("clicked", "Create Opportunity", ret.id, opp.Name);

      staffings = staffings.slice()
      orders = orders.slice()
      if (overageHours > 0) {
        staffings.push(staffingTwo)
        if (orders.length === 2) {
          // Create a separate order only for Vault+CRM
          orders.push(orderTwo)
        } else {
          // Copy the resource name for older services
          staffingTwo.Services_Resource__c = staffings[0].Services_Resource__c
          // Since we have a single order record, make sure it includes the overage info
          ;['SOW_Total__c','Managed_Services_Total__c','Overage_Rate__c'].forEach(field => {
            orders[0][field] += orderTwo[field]
          })
        }
      }

      let orderId
      for (let i = 0; i < staffings.length; i++) {
        const staffing = staffings[i]
        const order = orders[i]
        // Create a separate order only for Vault+CRM
        if (order) {
          order.Opportunity__c = ret.id;
          order.SEM_Credit__c = localStorage.getItem("sf_userid");
          const oret = await conn.sobject("VoD_Order__c").create(order)
          orderId = orderId || oret.id
          staffing.Order__c = oret.id
        } else {
          staffing.Order__c = orderId
        }

        staffing.Opportunity__c = ret.id;
        const cleanStaffing = _.omit(staffing, 'Work_Order_Hours__c', 'Work_Order_Rate__c')
        await conn.sobject("Services_Products__c").create(cleanStaffing)
      }

      const proposal = {
        userId: '3eceb28a-c5e8-49b6-97e1-44c4696973ab',
        companyId: companyId,
        parentId: parentId,
        parentType: 'catalog',
        status: 'draft',
        type: 'proposal',
        settings: { issueDate: moment().toISOString() },
      };
      proposal.name = opp.Name;
      proposal.settings = JSON.stringify(_.assign(proposal.settings, {
        value: 100 * staffings.reduce((sum, obj) => sum + obj.Work_Order_Hours__c * obj.Work_Order_Rate__c, 0),
        clientName: accountName,
        expiryDate: moment(opp.closeDate).toISOString(),
        email: getUser().email,
        items: [ itemId ],
        options: [ opp, staffings, orders, staffingTwo ],
      }));
      lazyCreateProposal(proposal);

      if (generateSOW) {
        const [
          opportunity,
          order,
        ] = await Promise.all([
          conn.sobject('Opportunity').findOne({ Id: oppId }),
          conn.sobject('VoD_Order__c').findOne({ Id: orderId }),
        ])
        const doc = docGen({ opportunity, order })
        toBuffer(doc, function(err, buffer) {
          if (err) return callback(err.toString());
          var base64data = new Buffer(buffer).toString("base64");
          conn.sobject('ContentVersion').create({
            Title: docName,
            PathOnClient: `${docName}.docx`,
            ContentLocation: 'S',
            VersionData: base64data
          })
            .then((contentVersion) => {
              return conn.query(`SELECT ContentDocumentId FROM ContentVersion WHERE Id = '${contentVersion.id}'`)
                .then(({ records: [contentDocumentId] }) => {
                  return conn.sobject('ContentDocumentLink').create({
                    ContentDocumentId: contentDocumentId.ContentDocumentId,
                    LinkedEntityId: oppId,
                    Visibility: 'InternalUsers',
                  })
                })
            })
            .then(() => callback(true, oppId))
            .catch(() => callback(err.toString()))
        });
      } else {
        callback(true, oppId);
      }
    } catch (e) {
      callback(e.toString())
    }
  }

  isLoggedIn() {
    return this.state.user.userId !== undefined;
  }

  clearUser() {
    this.setState({ user: {} });
    localStorage.removeItem("user/1i9");
    if (this.props.signinRequestReset) this.props.signinRequestReset();
  }
  componentDidMount() {
    this.props.lazyFetchDataTableByName('Hourly Rates')
    this.props.lazyFetchTemplates()
    if(isSalesforce()){
      sfData.accounts = [];
      this.sfFetchAllRecords('SELECT Name, Id FROM Services_Role__c', 'services_roles');
      this.sfFetchAllRecords('SELECT Name, Id FROM Services_Resource__c', 'services_resources');
      this.sfFetchAllRecords('SELECT ApiName FROM OpportunityStage', 'stages');
      sfData.regions = JSON.parse(localStorage.getItem('sf_regions'));
      sfData.service_types = JSON.parse(localStorage.getItem('sf_service_type'));
      sfData.editions = JSON.parse(localStorage.getItem('sf_editions'));
      sfData.revenuedepts = JSON.parse(localStorage.getItem('sf_revenuedepts'));
    }
    this.setState({ user: getUser() });
    this.setState({ startTime: new Date().getTime() });
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(this.props, "catalogFetch.pending") === false ||
        new Date().getTime() - this.state.startTime > 5000
    ) {
      this.log_transaction("viewed", "page", "home");
    }
    if (
      _.get(prevProps, "catalogFetch.pending") &&
      !_.get(this.props, "catalogFetch.pending")
    ) {
      const data = _.get(this.props, "catalogFetch.value.data");
      document.title = data.name;
      if (
        _.get(this.props, "locationFetch.pending") === false ||
        new Date().getTime() - this.state.startTime > 5000
      ) {
        this.log_transaction("viewed", "page", "home");
      }
    }
    if (
      (_.get(prevProps, 'catalogFetch.pending') || _.get(prevProps, 'dataTableRequest.pending')) &&
      _.get(this.props, "catalogFetch.fulfilled") &&
      _.get(this.props, 'dataTableRequest.fulfilled') &&
      _.get(this.props, 'templatesFetch.fulfilled')
    ) {
      this.setState({ loading: false });
    }

    if (
      _.get(prevProps, "signInFetch.pending") &&
      !_.get(this.props, "signInFetch.pending")
    ) {
      if (_.get(this.props, "signInFetch.rejected") === false) {
        const user = _.get(this.props, "signInFetch.value.data");
        this.setState({ user });
        localStorage.setItem("user/1i9", JSON.stringify(user));
      }
    }
  }

  getSfData(){
    return sfData;
  }

  getUser() {
    if (getUser()) {
      return getUser();
    }
    const user = {};
    user.email = "guest@generic.com";
    user.id = "cheese";
    return user;
  }

  isLoading(loading) {
    if (loading !== undefined) {
      this.setState({ loading: loading });
      return;
    }
    return loading;
  }

  isDimmed(dimmed) {
    if (typeof dimmed === "undefined") {
      return this.state.dimmed;
    }
    this.setState({ dimmed });
  }

  renderDimmed() {
    if (!this.state.dimmed) return null;

    return <div className="dimmed" />;
  }

  log_transaction(action, type, id, hname) {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    const transaction = {};
    transaction.parentType = "catalog";
    transaction.parentId = Constants.CATALOG_ID;
    if (!hname) {
      hname = id;
    }
    transaction.description = !isSalesforce() ?
      "A guest " + action + " " + (type ? type + " '" + hname + "'" : "")
    :
      "User " + this.getUser().email + " " + (type ? type + " '" + hname + "'" : "");
    transaction.requestData = {};
    transaction.companyId = this.props.catalogFetch.value.data.company.id;
    transaction.requestData.user = {};
    transaction.requestData.object = {};
    transaction.requestData.user.id = '3eceb28a-c5e8-49b6-97e1-44c4696973ab';
    transaction.requestData.user.email = getUser().email;
    if (hname) {
      transaction.requestData.object.name = hname;
    }
    transaction.requestData.object.type = type;
    transaction.requestData.object.verb = action;
    transaction.requestData.object.id = id;
    console.log(transaction);
    this.props.lazyLogTransaction(transaction);
  }

  render() {
    if (process.env.NODE_ENV !== 'development' && !localStorage.getItem('sf_session')) {
      return null
    }
    if (this.state.loading) {
      return (
        <FontAwesomeIcon icon="spinner" size="3x" spin className="loading" />
      );
    }
    return (
      <div className="App">
        <Site
          sfCreateOpportunity={this.sfCreateOpportunity}
          getSfData={this.getSfData}
          isSalesforce = {isSalesforce()}
          clearUser={this.clearUser}
          is_public={this.state.is_public}
          productRowsFetch={this.props.productRowsFetch}
          data={this.props.catalogFetch.value.data}
          productRowsLazyFetch={this.props.productRowsLazyFetch}
          templatesFetch={this.props.templatesFetch}
          log_transaction={this.log_transaction}
          lazySignIn={this.props.lazySignIn}
          signInFetch={this.props.signInFetch}
          isLoggedIn={this.isLoggedIn}
          isDimmed={this.isDimmed}
        />
        {this.renderDimmed()}
      </div>
    );
  }
}

export default connect(props => {
  return {
    lazyFetchDataTableByName: (dataTableName = '') => ({
      dataTableRequest: {
        url: `${Constants.APP_BASE}/api/v1/lists/search?name=${dataTableName}&companyId=${Constants.COMPANY_ID}`,
        headers: {
          token: Constants.AUTH
        },
        then: ({ data }) => data.length
          ? ({
            url: `${Constants.APP_BASE}/api/v1/list-rows/list-id/${data[0].id}`,
            headers: {
              token: Constants.AUTH
            },
            then: ({ data }) => {
              const getValueByKey = (key) => parseInt(data.find((v) => v.key.toLowerCase() === key.toLowerCase()).value, 10)
              const rates = {
                hourlyRate: getValueByKey('hours'),
                overageHourlyRate: getValueByKey('overage hours'),
                eurHourlyRate: getValueByKey('eur hours'),
                eurOverageHourlyRate: getValueByKey('eur overage hours'),
              }

              setCurrencyRates(rates)
              return { value: null }
            }
          })
          : ({
            value: null
          })
      }
    }),
    catalogFetch: {
      url: Constants.API_BASE + "/catalogs/" + Constants.CATALOG_ID,
      method: "GET",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        token: Constants.AUTH
      }
    },
    productRowsLazyFetch: productId => ({
      productRowsFetch: {
        url: `${Constants.APP_BASE}/api/v1/product-rows/product-id/${productId}`,
        headers: {
          Authorization: "ccc10ad7-b5aa-4c1d-8cbb-b4c3737efc8d"
        },
        force: true
      }
    }),
    lazyLogTransaction: transaction => ({
      logTransactionFetch: {
        url: `${Constants.API_BASE}/transactions/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: `{"data": ${JSON.stringify(transaction)}}`
      }
    }),
    lazySignIn: payload => ({
      signInFetch: {
        url: `${Constants.API_BASE}/users/auth`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: `${JSON.stringify(payload)}`
      },
      signinRequestReset: () => ({
        signinRequest: {
          value: null
        }
      })
    }),
    lazyCreateProposal: (proposal) => ({
      proposalCreate: {
        url: `${Constants.APP_BASE}/api/v1/documents/create`,
        method: 'POST',
        force: true,
        headers: {
          token: Constants.AUTH
        },
        body: `${JSON.stringify({data: proposal})}`,
      }
    }),
    lazyFetchTemplates: () => ({
      templatesFetch: {
        url: `${Constants.APP_BASE}/api/v1/templates/search?companyId=${Constants.COMPANY_ID}`,
        headers: {
          token: Constants.AUTH
        }
      }
    })
  };
})(App);
