const footer = `
    <td>
      <div style="mso-element:footer;" id=f1>
        <div style="mso-footer:f1;font-family:'Calibri';">
            <table style="width: 100%;" class=MsoFooter>
              <tr>
                <td style="text-align: left;">Veeva Confidential</td>
                <td style="text-align: right;">
                  Page <!--[if supportFields]><span
                  class=MsoPageNumber><span style='mso-element:field-begin'></span><span
                  style='mso-spacerun:yes'> </span><b>PAGE</b> <span style='mso-element:field-separator'></span></span><![endif]--><span
                  class=MsoPageNumber><span style='mso-no-proof:yes'><b>1</b></span></span><!--[if supportFields]><span
                  class=MsoPageNumber><span style='mso-element:field-end'></span></span><![endif]--><span
                  class=MsoPageNumber> of </span><!--[if supportFields]><span class=MsoPageNumber><span
                  style='mso-element:field-begin'></span> <b>NUMPAGES</b> <span style='mso-element:field-separator'></span></span><![endif]--><span
                  class=MsoPageNumber><span style='mso-no-proof:yes'><b>1</b></span></span><!--[if supportFields]><span
                  class=MsoPageNumber><span style='mso-element:field-end'></span></span><![endif]-->
                </td>
              </tr>
            </table>
        </div>
      </div>
    </td>
`

export default (headerContent) => {
  const header = headerContent
    ? `
    <td>
      <div style="mso-element:header" id=h1>
        <div style="mso-header:h1;">
          <table style="width: 100%;margin:0;padding:0;">
            <td style="margin:0;padding:0;">
              ${headerContent}
            </td>
          </table>
        </div>
      </div>
    </td>
`
    : ''
  return `
<table style="margin-left:50in;">
  <tr style="height:1pt;mso-height-rule:exactly;">
    ${header}
    ${footer}
  </tr>
</table>
`
}