import React, { Component } from "react";
import { InputGroup, Input, Button } from "reactstrap";
class Login extends Component {
  /*constructor(props){
    super(props);
  }*/

  render() {
    return (
      <div className="login">
        <InputGroup>
          <Input
            type="text"
            onChange={this.props.formManager}
            name="username"
            placeholder="email"
          />
        </InputGroup>
        <InputGroup>
          <Input
            type="password"
            onChange={this.props.formManager}
            name="password"
            placeholder="password"
          />
        </InputGroup>
        <Button onClick={this.props.onLoginSubmit}>Login</Button>
        {this.props.loginError && (
          <p className="alert-danger">{this.props.loginError}</p>
        )}
      </div>
    );
  }
}

export default Login;
